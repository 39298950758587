import { Link } from "@/components";
import { IViewTableColumn } from "@/components/table/ViewTable";
import { routerMap } from "@/router/agent_router";
import WebApi from "@/web/api";
import React from "react";
import CommonReport from "../components/CommonReport";

export default class SaleOrderReportPage extends React.PureComponent {
  private summaryColumns = [
    {
      title: '订货单(笔)',
      field: 'totalOrderCount',
    },
    {
      title: '商品数量(主计量单位)',
      field: 'totalProductCount',
    },
    {
      title: '下单客户(家)',
      field: 'totalCustomerCount',
    },
    {
      title: '订单总额(元)',
      field: 'totalOrderAmount',
      precision: 2
    },
    {
      title: '成本总额(元)',
      field: 'totalPurchaseAmount',
    },
    {
      title: '预估利润(元)',
      field: 'totalProfitAmount',
    },
  ];

  private columns: IViewTableColumn[] = [
    {
      title: '日期',
      dataIndex: 'date',
      width: 200,
      align: 'center',
      render: (value, row: any) => {
        if (row.orderCount == 0) {
          return value;
        }
        return <Link to={routerMap.reportSaleOrderDetail.getPath(row['date'])}>{value}</Link>
      }
    },
    {
      title: '订货单(笔)',
      dataIndex: 'orderCount',
      align: 'center',
      sorter: (a: any, b: any) => a.orderCount - b.orderCount,
    },
    {
      title: '商品数量(主计量单位)',
      dataIndex: 'productCount',
      align: 'center',
      sorter: (a: any, b: any) => a.productCount - b.productCount,
    },
    {
      title: '下单客户(家)',
      dataIndex: 'customerCount',
      align: 'center',
      sorter: (a: any, b: any) => a.customerCount - b.customerCount,
    },
    {
      title: '订单总额(元)',
      dataIndex: 'orderAmount',
      align: 'center',
      sorter: (a: any, b: any) => a.orderAmount - b.orderAmount,
    },
    {
      title: '成本总额(元)',
      dataIndex: 'purchaseAmount',
      align: 'center',
      sorter: (a: any, b: any) => a.purchaseAmount - b.purchaseAmount,
    },
    {
      title: '预估利润(元)',
      dataIndex: 'profitAmount',
      align: 'center',
      sorter: (a: any, b: any) => a.profitAmount - b.profitAmount,
    },
  ];
  render() {
    return <CommonReport
      filter={data => {
        return data.filter(i => i.orderCount > 0);
      }}
      showEmptyLabelText="仅显示有订单发生的日期"
      tableColumns={this.columns}
      pagination={false}
      summaryColumns={this.summaryColumns}
      fetch={data => WebApi.report_sale_order(data)}
    />
  }
}