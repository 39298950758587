import React from 'react';
import { Radio, DatePicker, Button, Statistic, message, Tooltip, Checkbox } from 'antd';
import { Chart, Area, Axis, Legend, Line } from 'bizcharts';
import { QuestionCircleOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { RadioChangeEvent } from 'antd/lib/radio';
import { Layout, ViewTable } from '@/components';
import { IViewTableColumn } from '@/components/table/ViewTable';
const style = require('./common_report.less');
import quarterOfYear from 'dayjs/plugin/quarterOfYear';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
dayjs.extend(quarterOfYear)

const { RangePicker } = DatePicker;

interface ICommonReportProps {
  dateQuickButton?: boolean;
  dateRangePicker?: boolean;
  summaryColumns: {
    title: string;
    field: string;
    precision?: number;
    valueStyle?: React.CSSProperties;
    prefix?: React.ReactNode;
    suffix?: React.ReactNode;
  }[];
  tableColumns: IViewTableColumn[];
  fetch(data: any): Promise<any>;
  pagination?: false | undefined;
  showEmptyLabelText: string;
  renderQuickFilter?(): React.ReactNode;
  filter(data: any[]): any[];
  onChangeDataSource?(): void;
}

interface IState {
  selectedDateRange: [dayjs.Dayjs, dayjs.Dayjs];
  selectedDateQuickValue: string;
  summaryData: any;
}

export default class CommonReport extends React.PureComponent<ICommonReportProps, IState> {

  private tableRef = React.createRef<ViewTable>();

  constructor(props) {
    super(props);

    this.state = {
      selectedDateRange: this.getDateQuickValue('本周'),
      selectedDateQuickValue: '本周',
      summaryData: {},
    };
  }

  render() {
    const { summaryColumns, tableColumns, dateQuickButton = true, fetch, pagination, renderQuickFilter, showEmptyLabelText = '仅显示有订单发生的数据' } = this.props;
    const { selectedDateQuickValue, selectedDateRange } = this.state;

    return (
      <Layout.Page flexColumn overHidden className="common-report">
        {/* 头部 */}
        {
          dateQuickButton && <section className="common-report-header">
            {
              renderQuickFilter && renderQuickFilter()
            }
            <Radio.Group value={selectedDateQuickValue} style={{marginLeft: renderQuickFilter ? 16 : 0}} onChange={this.clickDateQuick} buttonStyle="solid">
              <Radio.Button value="本周">本周</Radio.Button>
              <Radio.Button value="上周">上周</Radio.Button>
              <Radio.Button value="本月">本月</Radio.Button>
              <Radio.Button value="上月">上月</Radio.Button>
              <Radio.Button value="本季">本季</Radio.Button>
              <Radio.Button value="上季">上季</Radio.Button>
              <Radio.Button value="本年">本年</Radio.Button>
              <Radio.Button value="上年">上年</Radio.Button>
            </Radio.Group>

            <RangePicker style={{marginLeft: 16}}
              allowClear={false}
              disabledDate={current => {
                return current && current > dayjs().endOf('day');
              }}
              value={selectedDateRange as any}
              onChange={this.changeRangePick}
              renderExtraFooter={() => '*日期间隔须在1年之内'}
            />

            <Checkbox onChange={this.clickChangeShowEmpty} style={{marginLeft: 16}}>{showEmptyLabelText}</Checkbox>

            <div style={{flex: 1, textAlign: 'right'}}>
              <Tooltip placement="bottomLeft" title={
                <div>
                  <div>1、订货单提交订单后即计入统计；</div>
                  <div>2、作废的订货单不计入统计；</div>
                </div>
              }>
                <span>统计说明 <QuestionCircleOutlined /></span>
              </Tooltip>
              {/* <Button onClick={() => {
                message.warning('导出功能正在开发中，敬请期待...');
              }}>导出</Button> */}
            </div>
          </section>
        }
      
        {/* 总计 */}
        <section className="summary-bar">
          {
            summaryColumns.map((item, index) => {
              const value = this.state.summaryData[item.field];
              return <div key={item.field}>
                <Statistic title={item.title} valueStyle={item.valueStyle || {}} precision={item.precision} value={value} prefix={item.prefix} suffix={item.suffix}/>
              </div>
            })
          }
        </section>

        {/* 图 */}
        {/* <div style={{marginTop: 16}} ref={r => this.wrapper = r}>
          <Chart width={width} height={width/3} data={data} scale={cols}>
            
            <Axis name="date" title/>
            <Axis name="orderCount" title/>
            <Legend position="top" dy={-20} />
            <Tooltip />
            <Area position="date*orderCount"/>
            <Line position="date*orderCount"/>
          </Chart>
        </div> */}

        <ViewTable
          flex1
          fetch={(data) => {
            return fetch({...data, dateRange: this.state.selectedDateRange.map(item => item.format('YYYY-MM-DD'))}).then(res => {
              this.setState({
                summaryData: res,
              });
              if (pagination === false) {
                return res.list;
              }
              return res;
            });
          }}
          pagination={pagination}
          ref={this.tableRef}
          rowKey={(row: any) => row.date || row.id}
          columns={tableColumns}
        />
      </Layout.Page>
    )
  }

  private clickChangeShowEmpty = (e: CheckboxChangeEvent) => {
    if (e.target.checked) {
      this.tableRef.current?.setFilter(this.props.filter);
      this.tableRef.current?.filter();
    } else {
      this.tableRef.current?.clearFilter();
    }
  }

  private getDateQuickValue = (field: string): [dayjs.Dayjs, dayjs.Dayjs] => {
    const now = dayjs();
    if (field === '本周') {
      return [
        now.startOf('week'),
        now,
      ];
    }
    if (field === '上周') {
      return [
        now.startOf('week').subtract(1, 'week'),
        now.endOf('week').subtract(1, 'week')
      ];
    }
    if (field === '本月') {
      return [
        now.startOf('month'),
        now,
      ];
    }
    if (field === '上月') {
      return [
        now.month(dayjs().month() - 1).startOf('month'),
        now.month(dayjs().month() - 1).endOf('month')
      ];
    }
    if (field === '本季') {
      return [
        now.startOf('quarter'),
        now
      ];
    }
    if (field === '上季') {
      return [
        now.startOf('quarter').subtract(1, 'quarter'),
        now.subtract(1, 'quarter').endOf('quarter'),
      ];
    }
    if (field === '本年') {
      return [
        now.startOf('year'),
        now
      ];
    }
    if (field === '上年') {
      return [
        now.subtract(1, 'year').startOf('year'),
        now.subtract(1, 'year').endOf('year')
      ];
    }

    return [dayjs(), dayjs()];
  }

  private clickDateQuick = (e: RadioChangeEvent) => {
    this.props.onChangeDataSource && this.props.onChangeDataSource();
    const values = this.getDateQuickValue(e.target.value);
    this.setState({
      selectedDateQuickValue: e.target.value,
      selectedDateRange: values,
    }, () => {
      this.tableRef.current?.reset();
    });
  }

  changeRangePick = (values) => {
    this.props.onChangeDataSource && this.props.onChangeDataSource();
    const dateArray = values as [dayjs.Dayjs, dayjs.Dayjs];
    if (dateArray[1].diff(dateArray[0], 'day') > 365) {
      message.warning('日期间隔须在1年之内');
      return;
    }
    this.setState({
      selectedDateQuickValue: '',
      selectedDateRange: dateArray
    }, () => {
      this.tableRef.current?.reset();
    });
  }
}
